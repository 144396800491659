import { useQyGetHouseholdPurokDashboard } from "@factory/query/dashboard.query";
import "./dashboard-household-purok.scss";
import { LabelValue } from "@shared/models/label-value.interface";
import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import { Chart } from "primereact/chart";
import ErrorSection from "@shared/ui/error-section/error-section";
import { useState } from "react";
import { DashboardObject } from "@core/model/dashboard-object";

export function DashboardHouseholdPurok() {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const handleSuccess = (response: any) => {
    const dataObject = response as DashboardObject[];
    const cleanedArray = dataObject.map(
      ({ datasets, labels, ...rest }) => rest
    ) as LabelValue<number>[];

    const bgColor = [
      "#e0950a",
      "#ec27ec",
      "#1d29ce",
      "#20c429",
      "#d4692c",
      "#183b20",
      "#c51c24",
    ];
    const bgHoverColor = [
      "#b4790b",
      "#911091",
      "#0e168a",
      "#157a1a",
      "#9e4410",
      "#102916",
      "#850e14",
    ];

    const model = {
      labels: cleanedArray.map((item) => item.label),
      datasets: [
        {
          data: cleanedArray.map((item) => item.value),
          backgroundColor: bgColor,
          hoverBackgroundColor: bgHoverColor,
        },
      ],
    };
    const options = {
      cutout: "60%",
      responsive: true,
      maintainAspectRatio: false, // Allow the chart to grow with its container
    };

    setChartData(model);
    setChartOptions(options);
  };
  const { isLoading, isError, error } = useQyGetHouseholdPurokDashboard(
    true,
    handleSuccess
  );

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occurred" message={(error as any)?.message} />
    </div>
  );
  const chart = (
    <div className="dashboard-household-purok-chart">
      <h5 className="text-gray-500">Household Purok</h5>
      <div className="chart-container">
        <Chart
          type="doughnut"
          data={chartData}
          options={chartOptions}
          className="w-full h-full"
        />
      </div>
    </div>
  );

  return (
    <div id="DashboardHouseholdPurok" className="dashboard-household-purok">
      {isLoading && displayLoading}
      {isError && !isLoading && displayError}
      {!isLoading && !isError && chart}
    </div>
  );
}

export default DashboardHouseholdPurok;
