import { useState } from "react";
import "./dashboard-inhabitant.scss";
import { DashboardObject } from "@core/model/dashboard-object";
import { LabelValue } from "@shared/models/label-value.interface";
import { useQyGetInhabitantDashboard } from "@factory/query/dashboard.query";
import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import { FaUser } from "react-icons/fa";

export function DashboardInhabitant() {
  const [inhabitantCount, setInhabitantCount] = useState<number>(0);

  const handleSuccess = (response: any) => {
    const dataObject = response as DashboardObject[];
    const cleanedArray = dataObject.map(
      ({ datasets, labels, ...rest }) => rest
    ) as LabelValue<number>[];

    setInhabitantCount(cleanedArray[0]?.value || 0);
  };

  const { isLoading, isError, error } = useQyGetInhabitantDashboard(
    true,
    handleSuccess
  );

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );

  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occurred" message={(error as any)?.message} />
    </div>
  );

  const inhabitantCard = (
    <div className="dashboard-card-inhabitant-card">
      <div className="info">
        <span>{inhabitantCount}</span>
        <h5>Inhabitant</h5>
      </div>
      <FaUser className="icon inhabitant-icon" />
    </div>
  );

  return (
    <div id="DashboardInhabitant" className="dashboard-inhabitant">
      {isLoading && displayLoading}
      {isError && !isLoading && displayError}
      {!isLoading && !isError && <>{inhabitantCard}</>}
    </div>
  );
}

export default DashboardInhabitant;
