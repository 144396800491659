import { useState } from "react";
import "./dashboard-voter.scss";
import { DashboardObject } from "@core/model/dashboard-object";
import { LabelValue } from "@shared/models/label-value.interface";
import { useQyGetVoterDashboard } from "@factory/query/dashboard.query";
import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";
import ErrorSection from "@shared/ui/error-section/error-section";
import { Chart } from "primereact/chart";

export function DashboardVoter() {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const handleSuccess = (response: any) => {
    const dataObject = response as DashboardObject[];
    const cleanedArray = dataObject.map(
      ({ datasets, labels, ...rest }) => rest
    ) as LabelValue<number>[];

    const bgColor = ["#1d29ce", "#474850"];
    const bgHoverColor = ["#141c92", "#414247"];

    const model = {
      labels: cleanedArray.map((item) => item.label),
      datasets: [
        {
          data: cleanedArray.map((item) => item.value),
          backgroundColor: bgColor,
          hoverBackgroundColor: bgHoverColor,
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
          },
        },
      },
    };

    setChartData(model);
    setChartOptions(options);
  };
  const { isLoading, isError, error } = useQyGetVoterDashboard(
    true,
    handleSuccess
  );

  const displayLoading = (
    <div className="card">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occured" message={(error as any)?.message} />
    </div>
  );
  const chart = (
    <div>
      <h5 className="text-gray-500">Voters</h5>
      <div className=" flex justify-content-center">
        <Chart
          type="pie"
          data={chartData}
          options={chartOptions}
          className="w-full md:w-30rem"
        />
      </div>
    </div>
  );

  return (
    <div id="DashboardVoter" className="dashboard-voter w-full">
      {isLoading && displayLoading}
      {isError && !isLoading && displayError}
      {!isLoading && !isError && chart}
    </div>
  );
}

export default DashboardVoter;
