import "./list-inhabitant.scss";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { Sidebar } from "primereact/sidebar";
import { CiSearch } from "react-icons/ci";
import { MdClear } from "react-icons/md";

import ErrorSection from "@shared/ui/error-section/error-section";
import SearchInput from "@shared/ui/search-input/search-input";
import HeaderContent from "@shared/ui/header-content/header-content";
import { useQyGetInhabitant } from "@factory/query/inhabitant.query";
import { GetInhabitantModel } from "@factory/model/query-model-inhabitant";
import { TableSort } from "@shared/models/table-sort";
import { FilterInhabitant } from "../filter-inhabitant/filter-inhabitant";
import { useFilterInhabitantContext } from "../filter-inhabitant/filter-inhabitant.context";
import { GetInhabitantDto } from "@api/api";
import {
  calculatePercentage,
  getColorForPercentage,
} from "@core/utility/column-helper";
import SkeletonList from "@shared/ui/skeleton-list/skeleton-list";

export function ListInhabitant() {
  // THIS IS THE LOCAL DECLARATION
  const navigate = useNavigate();
  const [rowLimit, setRowLimit] = useState(20);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [first, setFirst] = useState(0);
  const [tableSort, setTableSort] = useState<TableSort>({
    sortField: undefined,
    sortOrder: undefined,
  });
  const [sort, setSort] = useState({});
  const [filterPanel, setFilterPanel] = useState(false);
  const { filterChipsElement, getFilterEntityValues } =
    useFilterInhabitantContext();

  // THIS IS THE QUERY DECLARATION
  const {
    data: inhabitantResponse,
    isLoading,
    isError,
    error,
  } = useQyGetInhabitant(
    searchTerm,
    rowLimit,
    pageNumber,
    sort,
    getFilterEntityValues()
  );

  // THIS IS THE LOCAL DECLARATION
  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };
  const handleTableSort = (e: DataTableStateEvent) => {
    setTableSort({
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
    const order = e.sortOrder === 1 ? "ASC" : "DESC";
    setSort({ [e.sortField]: order });
  };
  const navigateToRecord = (item: GetInhabitantModel) => {
    navigate(`${item.code}`);
  };
  const onPageChange = (event: PaginatorPageChangeEvent) => {
    const offsetValue = event.page * rowLimit;
    setFirst(event.first);
    setPageNumber(offsetValue);
    setRowLimit(event.rows);
  };
  const handleNew = () => {
    navigate(`new`);
  };
  const colName = (cell: GetInhabitantDto) => {
    return (
      <div>
        {cell.first_name} {cell.last_name}
      </div>
    );
  };

  const completionCol = (cell: GetInhabitantModel) => {
    const percentage = calculatePercentage(cell, ["deleted_at", "deleted_by"]);
    return (
      <div className="progress-container">
        <div
          className="progress-bar"
          style={{
            width: `${percentage}%`,
            backgroundColor: getColorForPercentage(percentage),
          }}
        >
          <span className="progress-text">{`${percentage}%`}</span>
        </div>
      </div>
    );
  };

  // THIS IS THE LOCAL RENDERS
  const displayLoading = (
    <div className="card-loading">
      <SkeletonList count={4} />
    </div>
  );
  const displayError = (
    <div className="card">
      <ErrorSection title="Error Occured" message={(error as any)?.message} />
    </div>
  );
  const filterElement = (
    <section>
      <div className="flex gap-4 items-center mb-2">
        <SearchInput
          clearIcon={<MdClear />}
          searchIcon={<CiSearch />}
          searchTerm={searchTerm}
          onSearch={handleSearch}
          placeholder="Search items"
          className="w-full block"
        />
        {/* <Button
          label="Filter"
          severity="secondary"
          badgeClassName="p-badge-danger"
          onClick={() => setFilterPanel(true)}
          outlined
        /> */}
      </div>

      {filterChipsElement}
      <Sidebar visible={filterPanel} onHide={() => setFilterPanel(false)}>
        <FilterInhabitant />
      </Sidebar>
    </section>
  );
  const grid = (
    <section className="mt-6">
      <h5 className="mb-2">
        Total: <b>{inhabitantResponse?.count}</b>
      </h5>

      <DataTable
        value={inhabitantResponse?.data}
        selectionMode="single"
        onSelectionChange={(e) => navigateToRecord(e.value)}
        onSort={handleTableSort}
        sortField={tableSort.sortField}
        sortOrder={tableSort.sortOrder}
      >
        <Column field="household_name" header="Household" sortable></Column>
        <Column body={(cell) => colName(cell)} header="Name" sortable></Column>
        <Column field="gender" header="Gender" sortable></Column>
        <Column field="civil_status" header="Civil_status" sortable></Column>
        <Column field="email" header="Email" sortable></Column>
        <Column field="mobile" header="Mobile" sortable></Column>
        <Column field="phone" header="Phone" sortable></Column>
        <Column
          body={(cell) => completionCol(cell)}
          header="Progress"
          sortable
        ></Column>
      </DataTable>

      <Paginator
        first={first}
        rows={rowLimit}
        totalRecords={inhabitantResponse?.count}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={onPageChange}
      />
    </section>
  );

  return (
    <div id="Inhabitant" className="inhabitant">
      <HeaderContent title="Inhabitant">
        <span className="flex gap-2">
          <Button label="New" onClick={handleNew} />
        </span>
      </HeaderContent>

      <div className="p-7">
        {filterElement}
        {isLoading && displayLoading}
        {isError && !isLoading && displayError}
        {!isLoading && !isError && grid}
      </div>
    </div>
  );
}

export default ListInhabitant;
