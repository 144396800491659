import {
  HouseholdFormSchema,
  MeetingFormSchema,
  AttendanceFormSchema,
  InhabitantFormSchema,
  PersonFormSchema,
  PersonAddressFormSchema,
  RoleFormSchema,
// DEFAULT IMPORT ADD
} from "./form.rule";
import { appFeature } from "../../app.feature";

export const loginFormDefault = {
  username: "",
  password: "",
};
export const householdFormDefault = {
  number: "",
  name: "",
  streetName: "",
  subdivision: "",
  zone: "",
  sitio: "",
  purok: "",
  barangay: "POBLACION II",
  municipality: "TAGBILARAN CITY",
  province: "BOHOL",
  region: "07",
  country: "Philippines",
  longitude: 0,
  latitude: 0,
  avatar: "",
  allowBoarders: false,
  hasBoardingPermit: false,
  houseMaterial: "",
  rooms: 0,
  toilets: 0,
  hasBackyardGarden: false,
  incomeSources: "",
  pets: 0,
  twoWheelVehicle: 0,
  threeWheelVehicle: 0,
  fourWheelVehicle: 0,
  photo: "",
} as HouseholdFormSchema;
export const meetingFormDefault = {
  title: "",
  information: "",
  date: "",
  time: "",
  location: "",
} as MeetingFormSchema;
export const attendanceFormDefault = {
  household: "",
  inhabitant: "",
  meeting: "",
} as AttendanceFormSchema;
export const inhabitantFormDefault = {
  householdCode: appFeature.config.default.householdCode,
  firstName: "",
  lastName: "",
  middleName: "",
  avatar: "",
  role: "",
  gender: "",
  civilStatus: "",
  birthday: "" as any,
  email: "",
  mobile: "",
  phone: "",
  isVerified: false,
  isDeceased: false,
  isVoter: false,
  isRepresentative: false,
  note: "",
  occupation: "",
  occupationPlace: "",
  isSchooling: false,
  gradeLevel: "",
  placeOfRegistration: "",
  bloodType: "",
  healthRemakrs: "",
  isPregnant: false,
  expectedLabourDate: "",
  isSingleParent: false,
  photo: "",
  streetName: "",
  subdivision: "",
  zone: "",
  sitio: "",
  purok: "",
  barangay: "POBLACION II",
  municipality: "TAGBILARAN CITY",
  province: "BOHOL",
  region: "07",
  country: "Philippines",
  longitude: 0,
  latitude: 0,
} as InhabitantFormSchema;
export const personFormDefault = {
  role: "",
  firstName: "",
  middleName: "",
  lastName: "",
  username: "",
  email: "",
  password: "",
  isVerified: false,
  avatar: "",
  activationCode: "",
  gender: "",
  birthday: "",
  isBlocked: false,
  mobileNumber: "",
  fatherName: "",
  motherName: "",
  emergencyContact_1Number: "",
  emergencyContact_1Name: "",
  emergencyContact_1Relationship: "",
  emergencyContact_2Number: "",
  emergencyContact_2Name: "",
  emergencyContact_2Relationship: "",
  sss: "",
  philhealth: "",
  pagibig: "",
  tin: "",
  medicalCondition: "",
  bloodType: "",
  elementary: "",
  highSchool: "",
  college: "",
  postCollege: "",
  undergraduate: "",
  curriculumVitae: "",
  supportingDoc: "",
  intentLetter: "",
  applicationLetter: "",
} as PersonFormSchema;
export const personAddressFormDefault = {
  userCode: "",
  streetName: "",
  subdivision: "",
  zone: "",
  sitio: "",
  purok: "",
  barangay: "",
  municipality: "",
  province: "",
  region: "",
  country: "",
  zip: "",
  longitude: 0,
  latitude: 0,
} as PersonAddressFormSchema;
export const roleFormDefault = {
  name: "",
  description: "",
} as RoleFormSchema;
// DEFAULT ADD
