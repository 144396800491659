import "./auth-shell.scss";
import { Outlet, useNavigate } from "react-router-dom";

import { AiOutlineLogout } from "react-icons/ai";
import { CiCircleChevRight } from "react-icons/ci";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";
import Sidebar from "@shared/ui/navigation/sidebar/sidebar";
import StorageService from "@shared/services/storage.service";
import { PROFILE } from "@core/utility/settings";
import { LocalProfile } from "@core/model/local-profile";
import { useAuthShellMenu } from "./auth-shell-menu.hook";
import { appConfig } from "../../../app.config";
import { appFeature } from "../../../app.feature";
import SidebarItem from "@shared/ui/navigation/sidebar/sidebar-item/sidebar-item";

const getLocalProfileFromStorage = () => {
  const profile = StorageService.load<LocalProfile>(PROFILE);
  return profile || null;
};

export function AuthShell() {
  const { getDesktopNavigation } = useAuthShellMenu();
  const profile = getLocalProfileFromStorage();
  const navigate = useNavigate();
  const navigation = getDesktopNavigation();
  const logo = `${
    appConfig.assetPath
  }/icon-192x192.png?${new Date().toISOString()}`;

  return (
    <div id="AuthShell" className="auth-shell">
      <section className="flex">
        <Sidebar
          title={appFeature.appName}
          logo={logo}
          logoWidth="w-12"
          username={profile?.name || "-"}
          userRole={profile?.roleName || "-"}
          userDepartment={profile?.departmentName || "-"}
          minifiedWidthClass="w-[80px]"
          onLogout={() => navigate("/login")}
          navClass="bg-gray-100"
          logoutElement={<AiOutlineLogout className="h-5 w-5 m-3" />}
          userInfoElement={<CiCircleChevRight className="h-7 w-7" />}
          headerLeftElement={<FaChevronLeft />}
          headerRightElement={<FaChevronRight />}
        >
          {navigation.map((item, id) => (
            <SidebarItem key={id} {...item} onAction={() => {}} />
          ))}
        </Sidebar>

        <div className="h-screen flex-1 px-4">
          <Outlet />
        </div>
      </section>
    </div>
  );
}

export default AuthShell;
